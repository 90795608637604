import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import AnimatedLine from "../../components/AnimatedLine/AnimatedLine";
import FadeInOut from "../../components/Animations/FadeInOut/FadeInOut";
import LineReveal from "../../components/Text/LineReveal/LineReveal";
import {
  Adress,
  Container,
  Details,
  Email,
  Header,
  ImageContainer,
  Inner,
  LineWrapper,
} from "./ContactPage.styles";
import { useLanguage } from "../../hooks/useLanguage";

function ContactSection({ data }) {
  const { image, title, slogan, adress, email } = data.contentfulContactPage;
  const [language] = useLanguage();

  return (
    <FadeInOut>
      <Container>
        <ImageContainer>
          <GatsbyImage image={getImage(image)} alt="" />
        </ImageContainer>
        <Inner>
          <Header>
            <LineReveal renderAs="h1" text={title} />
            {/* <LineReveal text={slogan} /> */}

            {language === "pl" && (
              <p>
                Szanowni Państwo Winnica Piwnice Półtorak nie prowadzi
                działalności hotelowej, enoturystycznej oraz związanej z
                degustacją dla osób indywidualnych. Wykaz restauracji oraz
                sklepów specjalistycznych prowadzących sprzedaż win Piwnice
                Półtorak, dostępna jest na naszej stronie internetowej
                www.piwnicepoltorak.pl oraz mediach społecznościowych. W
                sprawach oferty biznesowej, związanej z degustacją dla grup,
                oraz zakupem wina w ilościach hurtowych, proszę o kontakt z
                działem handlowym: radek@piwnicepoltorak.pl
              </p>
            )}
          </Header>
          <Details>
            <Email>
              <LineReveal
                style={{ fontWeight: 600, color: "white" }}
                text="E:"
              />
              <LineReveal text={email} />
              <LineReveal
                style={{ fontWeight: 600, color: "white" }}
                text="T:"
              />
              <LineReveal text="+48 889 780 094" />
            </Email>
            <Adress text={adress.adress} />
          </Details>
        </Inner>
        <LineWrapper>
          <AnimatedLine />
        </LineWrapper>
      </Container>
    </FadeInOut>
  );
}

export default React.memo(ContactSection);

export const query = graphql`
  query Contact($locale: String) {
    contentfulContactPage(node_locale: { eq: $locale }) {
      email
      slogan
      image {
        gatsbyImageData(quality: 100)
        title
        node_locale
      }
      title
      url
      adress {
        adress
      }
    }
  }
`;
